import {fill, isEqual, merge} from 'lodash';
import {IOptionSelectionVariant, IProductOptionsItem} from '../../types/productDef';
import {ProductInventoryStatus} from '../../constants';
import {IProductDTO} from '../../types/app-types';

export const VariantsGenerator = {
  fromProduct: (product: IProductDTO): IOptionSelectionVariant[] => {
    let optionSelectionVariants;
    if (product.isManageProductItems) {
      optionSelectionVariants = VariantsGenerator.generateDefaultVariants(product);
      VariantsGenerator.applyManagedItems(optionSelectionVariants, product);
    } else {
      optionSelectionVariants = [];
    }

    return optionSelectionVariants;
  },
  generateDefaultVariants: (product: IProductDTO): IOptionSelectionVariant[] => {
    const optionSelectionVariants = [];
    const options = product.options as IProductOptionsItem[];
    const indexArray: number[] = fill(Array(options.length), 0);

    let isFinished = false;

    while (!isFinished) {
      optionSelectionVariants.push(VariantsGenerator.getOptionSelectionVariant(indexArray, options, product));
      isFinished = VariantsGenerator.updateIndexArray(indexArray, options);
    }

    return optionSelectionVariants;
  },
  getOptionSelectionVariant: (indexArray: number[], options: IProductOptionsItem[], product: IProductDTO) => {
    const newSelectionArray = [];
    for (let i = 0; i < options.length; i++) {
      newSelectionArray.push(options[i].selections[indexArray[i]].id);
    }

    return VariantsGenerator.getOptionSelectionVariantObject(newSelectionArray, product);
  },
  updateIndexArray: (indexArray, options): boolean => {
    for (let i = indexArray.length - 1; i >= 0; i--) {
      indexArray[i]++;

      if (indexArray[i] >= options[i].selections.length) {
        indexArray[i] = 0;
      } else {
        return false;
      }
    }

    return true;
  },
  getOptionSelectionVariantObject: (selectionOption: number[], product: IProductDTO): IOptionSelectionVariant => {
    return {
      optionsSelections: selectionOption,
      isVisible: true,
      price: product.price,
      formattedPrice: product.formattedPrice,
      sku: product.sku,
      inventory: {
        status: ProductInventoryStatus.IN_STOCK,
        quantity: 0,
      },
      surcharge: 0,
    };
  },
  applyManagedItems: (optionSelectionVariants: IOptionSelectionVariant[], product: IProductDTO) => {
    product.productItems.forEach(item => {
      const optionSelection = optionSelectionVariants.filter((option: IOptionSelectionVariant) => {
        return isEqual(option.optionsSelections, item.optionsSelections);
      })[0];
      if (optionSelection) {
        merge(optionSelection, item);
      }
    });

    if (product.isTrackingInventory) {
      optionSelectionVariants
        .filter(variant => variant.inventory.quantity <= 0)
        .forEach(variant => (variant.inventory.status = ProductInventoryStatus.OUT_OF_STOCK));
    }
  },
};
