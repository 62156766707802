import _ from 'lodash';
import {ImageModeValues} from '@wix/wixstores-client-core/dist/es/src/media/constants';
import {
  GalleryNavigationLocation,
  GalleryNavigationPosition,
  GalleryNavigationType,
  InfoSectionLayoutId,
  LayoutId,
  TabsDirection,
} from '../constants';
import {getStyleParamsWithDefaults} from '@wix/wixstores-client-common-components/dist/src/outOfIframes/defaultStyleParams/getStyleParamsWithDefaults';
import {IWixStyleParams} from '@wix/wixstores-client-core/dist/src/types/wix-sdk';
import {IProductPageSettings, ProductPageAppProps, IStyleParamsOverrides} from '../types/app-types';

const fontStyleParam = false;

export const DEFAULT_STYLE_PARAMS: Partial<IWixStyleParams> = {
  booleans: {
    productPage_galleryZoom: true,
    productPage_navigation: true,
    productPage_productAction: true,
    productPage_productInfoSection: true,
    productPage_productPrice: true,
    productPage_productQuantity: true,
    productPage_productSku: true,
    productPage_socialNetworkfacebook: true,
    productPage_socialNetworkfancy: true,
    productPage_socialNetworkgoogle: true,
    productPage_socialNetworkpinterest: true,
    productPage_socialNetworktwitter: true,
    productPage_infoSectionShowDeviders: true,
    productPage_socialNetworks: true,
    productPage_openMinicart: true,
    full_width: false,
  },
  numbers: {
    productPage_descriptionVisibleLineNumbers: 0,
    productPage_galleryImageRatio: -1,
    productPage_galleryImageMode: ImageModeValues.CROP,
    productPage_layoutId: LayoutId.Classic,
    productPage_infoSectionTypeId: InfoSectionLayoutId.Collapse,
    productPage_infoSectionColumnNumber: 1,
  },
  fonts: {
    productPage_galleryNavigationLocation: {value: GalleryNavigationLocation.OUTSIDE, fontStyleParam},
    productPage_galleryNavigationPosition: {value: GalleryNavigationPosition.BOTTOM, fontStyleParam},
    productPage_galleryNavigationType: {value: GalleryNavigationType.THUMBNAILS, fontStyleParam},
    productPage_infoSectionAlignment: {value: TabsDirection.start, fontStyleParam},
  },
};

export const RESPONSIVE_STYLE_PARAMS_OVERRIDES: Partial<IWixStyleParams> = {
  booleans: {
    productPage_galleryZoom: false,
  },
  numbers: {
    productPage_infoSectionTypeId: InfoSectionLayoutId.Collapse,
    productPage_infoSectionColumnNumber: 3,
    productPage_descriptionVisibleLineNumbers: 0,
    productPage_galleryImageRatio: -1,
    productPage_layoutId: LayoutId.Mobile,
  },
  fonts: {
    productPage_galleryNavigationType: {value: GalleryNavigationType.DOTS, fontStyleParam: false},
    productPage_galleryNavigationPosition: {value: GalleryNavigationPosition.BOTTOM, fontStyleParam: false},
    productPage_galleryNavigationLocation: {value: GalleryNavigationLocation.OUTSIDE, fontStyleParam: false},
  },
};

export function getLayoutIdFromProps(props: ProductPageAppProps): LayoutId {
  return props.globals.style.styleParams.numbers.productPage_layoutId;
}

export function getStyleParamsFromProps(props: ProductPageAppProps): IProductPageSettings {
  const {
    globals: {
      style: {
        styleParams: {
          fonts: {
            productPage_galleryNavigationPosition: {value: navigationPositionString},
            productPage_galleryNavigationType: {value: navigationTypeString},
            productPage_galleryNavigationLocation: {value: navigationLocationString},
          },
          numbers: {
            productPage_galleryImageMode: imageMode,
            productPage_galleryImageRatio: imageRatioId,
            productPage_infoSectionTypeId: infoSectionTypeId,
            productPage_infoSectionColumnNumber: infoSectionColumnNumber,
          },
          booleans: {
            productPage_galleryZoom: shouldShowZoom,
            productPage_infoSectionShowDeviders: shouldShowDivider,
            productPage_productPrice: shouldShowPrice,
            productPage_productSku: shouldShowSku,
            productPage_productQuantity: shouldShowQuantity,
            productPage_navigation: shouldShowNavigation,
            productPage_productInfoSection: shouldShowInfoSection,
            productPage_socialNetworks: shouldShowSocialNetwork,
            productPage_productAction: shouldShowAddToCartButton,
            full_width: isFullWidth,
          },
        },
      },
    },
  } = props;
  const navigationPosition = navigationPositionString as GalleryNavigationPosition;
  const navigationType = navigationTypeString as GalleryNavigationType;
  const navigationLocation = navigationLocationString as GalleryNavigationLocation;
  return {
    navigationPosition,
    navigationType,
    navigationLocation,
    imageMode,
    imageRatioId,
    infoSectionTypeId,
    infoSectionColumnNumber,
    shouldShowDivider,
    shouldShowZoom,
    shouldShowPrice,
    shouldShowSku,
    shouldShowQuantity,
    shouldShowNavigation,
    shouldShowInfoSection,
    shouldShowSocialNetwork,
    shouldShowAddToCartButton,
    isFullWidth,
  };
}

export function getRuntimeStyleParams(styleParams: Partial<IWixStyleParams>, overrides: IStyleParamsOverrides = {}) {
  const result = getStyleParamsWithDefaults(styleParams as IWixStyleParams, () => ({defaults: DEFAULT_STYLE_PARAMS}));
  return _.merge({}, result, getRuntimeStyleOverrides(overrides));
}

export function getRuntimeStyleOverrides({isMobile, isResponsive}: IStyleParamsOverrides) {
  let overrides = {};

  if (isMobile || isResponsive) {
    overrides = _.merge(overrides, RESPONSIVE_STYLE_PARAMS_OVERRIDES);
  }

  if (isResponsive) {
    overrides = _.merge(overrides, {numbers: {productPage_layoutId: LayoutId.Responsive}});
  }

  return overrides;
}
