import {VariantsGenerator} from './variantsGenerator';
import {includes, isEqual, cloneDeep} from 'lodash';
import {IOptionSelectionVariant, IProductOptionSelectionItem, IProductOptionsItem} from '../../types/productDef';
import {IProductDTO} from '../../types/app-types';

export class ProductOptionsService {
  private readonly variants: IOptionSelectionVariant[] = [];
  public selectedVariant: IOptionSelectionVariant = null;
  public answers: IProductOptionSelectionItem[];
  private readonly isManagingVariants: boolean;
  private readonly isOutOfStock: boolean;

  public constructor(product: IProductDTO) {
    this.isManagingVariants = product.isManageProductItems;
    this.variants = VariantsGenerator.fromProduct(product);
    this.answers = ProductOptionsService.generateInitialAnswers(product.options as IProductOptionsItem[]);
    this.updateSelections(product, this.answers);
    this.isOutOfStock = !product.isInStock;
  }

  public updateSelections(product: IProductDTO, answers: IProductOptionSelectionItem[]): void {
    this.selectedVariant = this.getSelectedVariant(product, answers);
    this.updateVariantsIndications(answers, product.options as IProductOptionsItem[]);
  }

  private static generateInitialAnswers(options: IProductOptionsItem[]): IProductOptionSelectionItem[] {
    return options.map(option => {
      return option.selections.length === 1 ? option.selections[0] : null;
    });
  }

  private getSelectedVariant(product: IProductDTO, answers: IProductOptionSelectionItem[]): IOptionSelectionVariant {
    let result = null;
    if (answers.indexOf(null) < 0) {
      const answersIds = answers.map(x => x.id);
      const selected = product.productItems.filter(variant => isEqual(variant.optionsSelections, answersIds));
      result = selected[0] || null;
    }
    return result;
  }

  private updateVariantsIndications(answers: IProductOptionSelectionItem[], options: IProductOptionsItem[]): void {
    const answersIds = answers.map(answer => (answer !== null ? answer.id : null));
    options.forEach((option, optionIndex) => {
      option.selections.forEach(optionSelection => {
        const modifiedAnswers = this.getAnswersIdsLikeTheCurrentIsSelected(answersIds, optionIndex, optionSelection);
        optionSelection.isDisabled = !this.hasInStock(modifiedAnswers);
        optionSelection.isVisible = this.hasVisible(modifiedAnswers);
      });
    });
  }

  private getAnswersIdsLikeTheCurrentIsSelected(
    answersIds: number[],
    optionIndex: number,
    optionSelection: IProductOptionSelectionItem
  ): number[] {
    let modifiedAnswers = cloneDeep(answersIds);
    modifiedAnswers[optionIndex] = optionSelection.id;
    modifiedAnswers = modifiedAnswers.filter(answerId => answerId !== null);
    return modifiedAnswers;
  }

  private hasVisible(answers: number[]): boolean {
    if (this.isManagingVariants) {
      return this.variants.some(
        variant => variant.isVisible && answers.every(id => includes(variant.optionsSelections, id))
      );
    } else {
      return true;
    }
  }

  private hasInStock(answers: number[]): boolean {
    if (this.isManagingVariants) {
      return this.variants.some(
        variant =>
          variant.inventory.status === 'in_stock' && answers.every(id => includes(variant.optionsSelections, id))
      );
    } else {
      return !this.isOutOfStock;
    }
  }
}
