export const query = `query getProductsWithSeo($externalId: String!, $limit: Int, $filters: ProductFilters, $sort: ProductSort) {
  appSettings(externalId: $externalId) {
    widgetSettings
  }
  catalog{
    products(limit: $limit, filters: $filters, sort: $sort, onlyVisible: true) {
      list{
        id
        description
        isVisible
        sku
        ribbon
        price
        comparePrice
        formattedPrice
        formattedComparePrice
        seoTitle
        seoDescription
        createVersion
        digitalProductFileItems{
          fileId
          fileType
          fileName
        }
        productItems {
          price
          comparePrice
          formattedPrice
          formattedComparePrice
          optionsSelections
          isVisible
          inventory {
            status
            quantity
          }
          sku
          weight
          surcharge
        }
        name
        isTrackingInventory
        inventory {
          status
          quantity
        }
        isVisible
        isManageProductItems
        isInStock
        media {
          id
          url
          fullUrl
          thumbnailFullUrl: fullUrl(width: 50, height: 50)
          mediaType
          videoType
          videoFiles {
            url
            width
            height
            format
            quality
          }
          width
          height
          index
          title
        }
        customTextFields {
          title
          isMandatory
          inputLimit
        }
        nextOptionsSelectionId
        options {
          title
          optionType
          selections {
            id
            value
            description
            linkedMediaItems {
              url
              fullUrl
              thumbnailFullUrl: fullUrl(width: 50, height: 50)
              mediaType
              width
              height
              index
              title
              videoFiles {
                url
                width
                height
                format
                quality
              }
            }
          }
        }
        productType
        urlPart
        additionalInfo {
          id
          title
          description
          index
        }
        discount {
            mode
            value
        }
        currency
        weight
        seoJson
      }
    }
  }
}`;
