export function queryToString(queryStringObj: Object) {
  return Object.keys(queryStringObj)
    .map(
      prop =>
        `${prop}=${encodeURIComponent(queryStringObj[prop])
          .replace(/%20/g, '+')
          .replace(/\*/g, '%2A')}`
    )
    .join('&');
}
